var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'main-block',
    {
      _open: _vm.isOpen,
    },
  ]},[_c('div',{staticClass:"_desctop"},[_c('h2',{staticClass:"main-block__title",domProps:{"innerHTML":_vm._s(_vm.i18n.title)}}),_c('img',{staticClass:"main-block__img",attrs:{"src":require("./girl.png"),"alt":"счастливый человек с деньгами в руках"}})]),_c('div',{staticClass:"_mobile"},[_vm._m(0),_c('h2',{staticClass:"main-block__title",domProps:{"innerHTML":_vm._s(_vm.i18n.titleMobile)}})]),_c('Calculator',{staticClass:"main-block__calculator"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-block__img"},[_c('img',{attrs:{"src":require("./mobile-img.svg"),"alt":"0%"}})])
}]

export { render, staticRenderFns }