<template>
  <form
    class="calculator p-4"
    method="get"
    action="/primary"
  >
    <label class="w-100 mb-3">
      <div class="d-flex justify-content-between align-items-center">
        <span class="__title">Сумма:</span>
        <span class="__amount">
          <b>{{ Number(amount).toLocaleString() }}</b> ₽
        </span>
      </div>
      <Input
        v-model="amount"
        type="range"
        name="amount"
        :step="1000"
        :min="amountObj.min"
        :max="amountObj.max"
      />
    </label>
    <label class="w-100 mb-3">
      <div class="d-flex justify-content-between align-items-center">
        <span class="__title">Срок:</span>
        <span class="__amount">
          <b>{{ Number(term).toLocaleString() }}</b>
          {{ morph(['день', 'дня', 'дней'], Number(term)) }}
        </span>
      </div>
      <Input
        v-model="term"
        type="range"
        name="term"
        :step="1"
        :min="termObj.min"
        :max="termObj.max"
      />
    </label>
    <div class="calculator_result mb-3">
      <span> Вернете: </span>
      <div>
        <del>{{ Number(valueDel).toLocaleString() }} ₽</del>
				&nbsp;
        <span><b>{{ Number(amount).toLocaleString() }}</b> ₽</span>
      </div>
    </div>
    <Button
      type="submit"
      class="w-100 mt-4 get-money"
    >
      <span>Получить деньги</span>
      <Icon type="arrow-back" />
    </Button>
  </form>
</template>

<style type="css">
.calculator {
  display: inline-flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  border-radius: 24px 24px 0 0;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.25);
}

.calculator .__title {
  font-size: 16px;
}

.calculator .__amount {
  font-size: 24px;
}

.calculator_result {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

/* Градиент */
@media (min-width: 767px) {
  .calculator {
    width: 448px;
    box-shadow: none;
  }
}

@media (width: 767px) {
  .calculator {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .calculator .button {
    justify-content: center;
    font-size: 20px;
    font-weight: 700;
    border: 1px solid #333333;
    width: 100%;
  }

  .calculator .button .icon-container {
    display: none;
  }
}
</style>

<script>
export default {
  name: "Calculator",
  data() {
    return {
      amount: 10000,
      term: 12,
      amountObj: { min: 4000, max: 30000, default: 10000 },
      termObj: { min: 3, max: 30, default: 12 },
    };
  },
  computed: {
    valueDel() {
      const sum = Number(this.amount);
      return sum + Number(this.term) * (sum / 100);
    },
  },
  methods: {
    morph(forms, count) {
      if (count % 10 === 1 && count % 100 !== 11) {
        return forms[0];
      }

      if (
        count % 10 >= 2 &&
				count % 10 <= 4 &&
				(count % 100 < 10 || count % 100 >= 20)
      ) {
        return forms[1];
      }

      return forms[2];
    },
  },
};
</script>
