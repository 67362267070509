<template>
  <div class="landing-wrapper">
    <Landing />
  </div>
</template>

<style lang="scss">
.landing-wrapper {
  height: 100%;
}
</style>

<script>
import Landing from "./landing.vue";

export default {
  name: "LandingWrapper",
  components: {
    Landing,
  },
};
</script>
