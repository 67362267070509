export default {
  mainBlock: {
    title: "Для новых клиентов<br />займ под 0%",
    titleMobile: "всем<br />новым<br />клиентам",
  },
  advantages: {
    list: [
      {
        icon: "timer",
        title: "Быстро",
        content: "5 минут от заявки<br />до получения денег",
      },
      {
        icon: "satisfied",
        title: "просто",
        content: "Нужен только мобильный<br />телефон и паспорт",
      },
      {
        icon: "language",
        title: "онлайн",
        content: "Не выходя из дома,<br />в течение 10 минут",
      },
    ],
  },
  howGet: {
    title: "Как это работает?",
    caption:
			"Вы заполняете анкету — мы подберем для вас<br /><b>лучшее предложение по займу</b>",
    steps: [
      {
        title: "Заполните анкету",
        description: "Вам понадобится только паспорт и телефон",
      },
      {
        title: "Получите подтверждение",
        description: "Максимально выгодное предложение по займу",
      },
      {
        title: "Получите деньги",
        description: "Переведем деньги удобным для вас способом",
      },
    ],
  },
  conditions: {
    title: "Условия",
    description: ["Поможем найти деньги ", "в <b>сложных ситуациях</b>"],
    list: [
      {
        term: "Возраст:",
        definition: "от 18 лет",
      },
      {
        term: "Первый займ:",
        definition: "от 0%",
      },
      {
        term: "Территория:",
        definition: "Все регионы России",
      },
    ],
    more: {
      title: "Подробнее об условиях",
      detailed:
				"У пользователя сервиса есть возможность получить займ до 100 000 рублей на срок до 30 дней. Комиссия за выдачу займа составляет от 0%. Переплата по займу составляет от 0 рублей и зависит от условий индивидуального кредитного предложения, подобранного для клиента.",
    },
  },
  feedback: {
    title: "Отзывы",
    reviews: [
      {
        city: "Москва",
        name: "Евгений Иванов",
        content:
					"Вашим сервисом пользуюсь не первый раз, все удобно и понятно. Не забывайте вернуть займ вовремя.",
      },
      {
        city: "Ростов на Дону",
        name: "Дмитрий Краснов",
        content:
					"Ответ по заявке пришел мгновенно. Деньги были на карте через 10 минут.",
      },
      {
        city: "Санкт-Петербург",
        name: "Анна Петрова",
        content:
					"Не хватало для покупки телефона, пришлось воспользоваться сервисом. Быстро получила одобрение и уже вечером купила новый телефон. Спасибо вам!",
      },
      {
        city: "Томск",
        name: "Михаил Игнатьев",
        content:
					"Давно искал подобный сервис, где будет доступ ко многим МФО.Часто пользуюсь.",
      },
    ],
  },
  questions: {
    title: "Частые вопросы",
    list: [
      {
        q: "Как оформить займ?",
        a: "Для получения займа - выберите сумму и срок займа, заполните анкету, получите подтверждение и получите деньги на карту ",
      },
      {
        q: "Как погасить займ?",
        a: "Вы можете погасить займ платежом с банковской карты",
      },
      {
        q: "Как долго рассматривается заявка?",
        a: "Чтобы заполнить анкету, вам потребуется около 3 минут, рассмотрение заявки займет еще 5 минут, — и по истечении этого срока вы получите деньги удобным способом.",
      },
      {
        q: "Получу ли я деньги с плохой кредитной историей?",
        a: "Мы понимаем, что ситуации могут быть разными, и работаем со всеми клиентами: подберем для вас организации, которые готовы работать с заемщиками даже при наличии плохой кредитной истории.",
      },
    ],
  },
};
